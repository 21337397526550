@tailwind base;
@tailwind components;
@tailwind utilities;

strong, b {
  font-weight: bold;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: #000;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 222.2 84% 4.9%;
    --foreground: 210 40% 98%;

    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;

    --popover: 222.2 84% 4.9%;
    --popover-foreground: 210 40% 98%;

    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 11.2%;

    --secondary: 217.2 32.6% 17.5%;
    --secondary-foreground: 210 40% 98%;

    --muted: 217.2 32.6% 17.5%;
    --muted-foreground: 215 20.2% 65.1%;

    --accent: 217.2 32.6% 17.5%;
    --accent-foreground: 210 40% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 40% 98%;

    --border: 217.2 32.6% 17.5%;
    --input: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@keyframes animate-blue {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: hsl(212 96% 78%);
  }
  100% {
    background-color: transparent;
  }
}
@keyframes animate-lilac {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: hsl(242, 100%, 94%);
  }
  100% {
    background-color: transparent;
  }
}
@keyframes animate-yellow {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: hsl(50, 98%, 64%);
  }
  100% {
    background-color: transparent;
  }
}
@keyframes animate-red {
  0% {
    background-color: transparent;
  }
  50% {
    background-color: hsl(0, 94%, 82%);
  }
  100% {
    background-color: transparent;
  }
}

@keyframes animate-green {
  0% {
    background: "transparent";
  }
  50% {
    background: #96ca77;
  }
  100% {
    background: "transparent";
  }
}

.animate-blue {
  animation: 3s animate-blue;
}
.animate-lilac {
  animation: 3s animate-lilac;
}
.animate-red {
  animation: 3s animate-red;
}
.animate-yellow {
  animation: animate-yellow 3s;
}
.animate-green {
  animation: animate-green 3s;
}

.previewSection__active {
  position: relative;
  &::before {
    content: "";
    height: 100%;
    min-height: 1.25rem;
    width: 8px;
    display: block;
    position: absolute;
    top: 0;
    left: -4rem;
    background-color: #000AFF;
  }
}
.previewSpanSection__active {
  position: relative;
}

@layer components {
  .button {
    @apply inline-flex
      min-w-[5rem] items-center
      justify-center
      rounded-md border border-transparent
      bg-electricBlue px-3 py-2
			text-sm text-white
      hover:bg-electricBlue  disabled:border-black
      disabled:bg-gray-200 disabled:text-black;
  }
  .buttonSecondary {
    @apply inline-flex
      min-w-[5rem] items-center
      justify-center
      rounded-md border border-black
      bg-white px-3
      py-2 text-sm
      text-black hover:bg-gray-100;
  }
  .buttonDropdown {
    @apply inline-flex h-full
    items-center justify-center
    rounded-r-lg border border-transparent border-l-white
    bg-electricBlue px-3
    py-2 text-sm text-white
    hover:bg-electricBlue  disabled:border-black
    disabled:bg-gray-200 disabled:text-black;
  }
  .buttonSectionSeparator::before {
    content: "";
    position: absolute;
    top: calc(50%);
    left: -2rem;
    width: calc(100% + 4rem);
    height: 1px;
    background-color: black;
    z-index: -1;
  }

  .buttonDisabled {
    /* @apply bg-gray-200 hover:cursor-default hover:bg-gray-200; */
    @apply cursor-auto opacity-25;
    /* cursor-not-allowed */
  }

  .certificateTableCell {
    @apply px-6
      py-4
      text-sm text-black
      break-words;
      hyphens: auto;
  }

  .drawerSection {
    @apply space-y-6 p-8 first:border-none;
    @apply border-t border-black;

    &.Jobs,
    &.Training {
      @apply space-y-0;
      border-bottom: none;
      h2 {
        // @apply px-8 py-4;
      }
      form {
        // @apply px-8 py-2;
        &:last-of-type {
          @apply border-none;
        }
      }
    }
    &.Training {
      border-top: none;
    }
  }

  .labelTop {
    @apply mb-2 text-sm font-normal text-black;
  }

  .labelRed {
    @apply max-w-max
    rounded-xl bg-red-100
    px-4 py-1
    text-red-800;
  }
  .labelYellow {
    @apply max-w-max
    rounded-xl bg-yellow-100
    px-4 py-1
    text-yellow-800;
  }
  .labelGray {
    @apply max-w-max
    rounded-xl
    bg-gray-100
    px-4 py-1
    text-gray-800;
  }
  .labelGreen {
    @apply max-w-max
    rounded-xl
    bg-green-100
    px-4 py-1
    text-green-800;
  }
  .labelBlue {
    @apply max-w-max
    rounded-xl
    bg-blue-100
    px-4 py-1
    text-blue-800;
  }
  .labelBlack {
    @apply max-w-max
    rounded-xl bg-black
    px-4 py-1
    text-white;
  }

  .icon {
    @apply h-4 w-4;
  }
  .iconS {
    @apply h-2 w-2;
  }
  .iconL {
    @apply h-6 w-6;
  }
  .iconXL {
    @apply h-8 w-8;
  }
  .iconXXL {
    @apply h-10 w-10;
  }
}

.cl-footer {
  @apply hidden;
}

.cl-header {
  @apply hidden;
}


.cl-card,
.cl-cardBox,
.cl-rootBox {
  @apply sm:w-full sm:max-w-md;
}
.cl-card {
  @apply p-0 px-2 shadow-none;
}

.cl-cardBox {
  @apply shadow-none;
}

.cl-formFieldInput.cl-input {
  @apply shadow-none border relative m-0 outline-none transition rounded-lg max-h-none
  h-10 w-full border-none ring-1 ring-inset ring-black focus:ring-2 focus:ring-inset focus:ring-ocean placeholder-gray-400;
}

.cl-formFieldInput.cl-input.cl-internal-1o17xpc,
.cl-formFieldInput.cl-input.cl-internal-1hmtz31 {
  @apply shadow-none hover:shadow-none focus:shadow-none;
}

.cl-formFieldInput.cl-checkbox.cl-formFieldInput__signOutOfOtherSessions.cl-checkbox__signOutOfOtherSessions.cl-internal-18ykhv3 {
  @apply h-4 w-4 bg-gray-100 checked:bg-electricBlue hover:cursor-pointer focus:ring-blue-500 rounded border border-black checked:border-electricBlue;
}

.cl-formFieldInput.cl-input.cl-formFieldInput__password.cl-input__password {
  @apply shadow-none;
}

.cl-formFieldInput.cl-input.cl-formFieldInput__identifier.cl-input__identifier.cl-required {
  @apply shadow-none;
}

.cl-formButtonPrimary.cl-button {
  @apply button shadow-none m-0 after:content-none rounded-md ;
}

.cl-formButtonPrimary.cl-button.cl-internal-ttumny {
  @apply shadow-none focus:shadow-none;
}

.cl-buttonArrowIcon {
  @apply hidden;
}

.cl-formFieldLabel {
  @apply labelTop font-bold mb-0;
}

.cl-dividerRow {
  @apply h-5;
}

.cl-dividerLine {
  @apply h-px w-full bg-black;
}

.cl-dividerText {
  @apply text-black;
}

.cl-alternativeMethodsBlockButton.cl-button,
.cl-socialButtonsBlockButton.cl-button,
.cl-socialButtonsIconButton.cl-button__custom_ink_2_ikea,
.cl-socialButtonsIconButton.cl-button__custom_ink2_ikea {
  @apply shadow-none buttonSecondary focus:shadow-none;
}

.cl-formFieldInputShowPasswordButton.cl-button {
  @apply bg-transparent shadow-none disabled:cursor-default buttonSecondary border-none min-w-0;
}

.cl-formFieldInputShowPasswordButton.cl-button.cl-internal-1r7rnyr {
  @apply bg-transparent;
}

.cl-formFieldAction.cl-formFieldAction__password.cl-internal-z0jvxn {
  @apply bg-transparent hover:no-underline text-sm text-black;
}

.cl-internal-1tt4afi {
  @apply hover:no-underline;
}

.cl-backLink.cl-internal-16htnu8 {
  @apply text-sm text-black;
}

.cl-footerActionLink.cl-internal-1v2koqu {
  @apply text-sm text-black hover:no-underline;
}

.cl-formResendCodeLink.cl-button.cl-internal-q2718p {
  @apply text-sm text-black hover:no-underline;
}

.cl-alternativeMethodsBlockButton.cl-button.cl-internal-kvcwmp {
  @apply shadow-none buttonSecondary focus:shadow-none;
}

.cl-otpCodeFieldInput.cl-input.cl-internal-1hxxuyc {
  @apply text-sm text-black shadow-none focus-within:shadow-none border-none ring-1 ring-inset ring-black focus:ring-2 focus:ring-inset focus:ring-ocean ;
}

.cl-formResendCodeLink.cl-button.cl-internal-q2718p {
  @apply shadow-none focus:shadow-none text-sm text-black disabled:opacity-60;
}

.cl-alternativeMethodsBlockButtonArrow {
  @apply hidden;
}

.cl-internal-eukaea {
  @apply hidden;
}

.cl-socialButtons {
  grid-template-columns: repeat(1, 1fr);
  gap: 1rem;
}
.clerk-hide-google .cl-button__google {
  display: none;
}

.clerk-hide-microsoft .cl-button__microsoft {
  display: none;
}
.clerk-hide-ikea .cl-button__custom_ink_2_ikea {
  display: none;
}

.clerk-hide-ikea .cl-button__custom_ink2_ikea {
  display: none;
}
.clerk-hide-divider .cl-dividerRow {
  display: none;
}
:root {
  font-family: "Source Sans Pro", "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif;
}

.font-default {
  font-family: "Source Sans Pro", "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif;
}

html,
body {
  font-size: 16px !important;
  font-family: "Source Sans Pro", "Segoe UI", Tahoma, Geneva, Verdana,
    sans-serif !important;
  color: black !important;
}

h2 {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

[type="text"] {
  border-color: black;
}

.bg-yellow-300 {
  background-color: #faf089;
}
.bg-red-300 {
  background-color: #feb2b2;
}

// scrollbar
// *::-webkit-scrollbar {
// 	width: 10px;
// }
// *::-webkit-scrollbar-track {
// 	background: theme( 'colors.gray.200' );
// }
// *::-webkit-scrollbar-thumb {
//   background-color: theme( 'colors.gray.400' );
//   border-radius: 5px;
// 	border: none;
// }

// // The emerging W3C standard that is currently Firefox-only
// * {
// 	scrollbar-width: 10px;
// 	scrollbar-color: theme( 'colors.gray.400' ) theme( 'colors.gray.200');
// }

.content {
  /* Adjust the width and height as needed */
  width: 800px;
  height: 200px;
  padding: 0 20px 0 50px;
}

.contrast {
  padding: 10px;
  margin: 10px 5px 0 0;
  border-radius: 4px;
  border: 0;
  font-weight: 600;
  cursor: pointer;
}
/* End of styles for home page */

:root {
  --ff-primary-color: #4c48ef !important;
  --ff-secondary-color: #616a7d !important;
  --ff-text-color: #090b2b !important;
  --ff-dialog-border-radius: 4px !important;
  --ff-border-radius: 5px !important;
  --ff-bg-fade: rgba(0, 0, 0, 0.2) !important;
}

/* The container of the error component */
/* .ff_error_container {
}*/

/* The heading text you see in the error component */
/* .ff_error_heading {
}*/

/* The description text you see in the error component */
/* .ff_error_text {
}*/
